<template>
  <MainLayout>
    <template v-slot:toolbar>
      <!--begin::Page title-->
      <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <!--begin::Title-->
        <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Relatório de status dos testes</h1>
        <!--end::Title-->
      </div>
      <!--end::Page title-->
    </template>

    <!-- CONTENT -->
    <!--begin::Products-->
    <div class="card card-flush">
      <!--begin::Card body-->
      <div class="card-body pt-0">
        <div class="text-center py-5">
          <form @submit.prevent="">
            <div class="mt-5">
              <Button
                class="btn-primary"
                :loading="loader"
                @click="submit"
              >
                Extrair relatório
              </Button>
            </div>
          </form>
        </div>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Products-->
    <!-- END CONTENT -->
  </MainLayout>
</template>

<script>

import NotificationService from '@/modules/notifications/services/notifications-service'
import ReportsService from '@/modules/reports/services/reports-service'

import Button from '@/components/common/Button/Button'
import MainLayout from '@/components/layouts/MainLayout'

export default {
  components: {
    Button,
    MainLayout
  },
  metaInfo () {
    return {
      title: this.title
    }
  },
  data () {
    return {
      loader: false
    }
  },
  created () {
  },
  computed: {
  },
  methods: {
    /**
     * Submit
     */
    async submit () {
      this.loader = true
      try {
        await ReportsService.requestTestStatusReport()
        const notification = {
          notification: {
            type: 'success',
            content: 'Relatório solicitado com sucesso'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    }
  }
}
</script>
