import store from '@/store'

const createGlobalSuccessNotification = notification => {
  store.dispatch('setNotification', notification)

  setTimeout(() => {
    store.dispatch('clearNotification')
  }, 3000)
}

export default {
  createGlobalSuccessNotification
}
